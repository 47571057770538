import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { fg } from '@atlaskit/platform-feature-flags';
import {
	CancelError,
	CancellablePromise,
	isSearchResultJiraIssue,
	type ThirdPartyFilters,
} from '@atlassian/search-client';

import { is3pProductKey } from '../../../common/constants/products';
import type { SelectedFiltersType } from '../../../common/constants/types';
import { filterSearchResults } from '../../../common/utils/filter-search-results';
import { measureAsyncFunction } from '../../../common/utils/measure-duration';
import {
	getProductsInResults,
	getResultTypeCounts,
	getSectionsAndResultsCount,
} from '../../../common/utils/quick-find/analytics';
import { onSearchResultsShown } from '../../../common/utils/quick-find/events/search-results-shown';
import { State } from '../../../common/utils/quick-find/state';
import { useDebouncedCallback } from '../../../common/utils/use-debounced-callback';
import { useLogException } from '../../error-boundary';
import { useSelectedFilters } from '../../filters/selected';
import { fetchJiraFields } from '../../results/fetch-results';
import {
	useBootstrap,
	useIsSearchReady,
	useRetryCount,
	useSearchActions,
	useSearchSessionId,
	useSelectedProduct,
} from '../../store';
import {
	type SelectedQueryFilter,
	useContent,
	useCurrentQueryFilter,
	useQuickFindActions,
	useQuickFindOpen,
	useQuickFindQuery,
	useSelectedQueryFilters,
} from '../../store/quick-find';
import { useRequestedBackendExperiments } from '../../store/search';
import {
	getCommonQueryAttributes,
	useQuickFindAnalytics,
	useQuickFindAttributes,
} from '../analytics';

import { fetchRecentActivities } from './activities';
import { useQuickFindResultsCallbacks } from './callbacks';
import { fetchPeopleAndTeamsResults } from './people-and-teams';
import {
	fetchAtlasEntities,
	fetchConfluenceAnd3PEntities,
	fetchConfluenceSpaces,
	fetchJiraEntities,
	fetchMercuryEntities,
	getThirdPartyFiltersForQuickFind,
} from './product-entities';
import { getRecentQueries } from './recent-queries';
import {
	getQuickFindContent,
	type PeopleAndTeams,
	type RecentActivities,
	type RecentQueries,
	type SearchResults,
} from './types';

const DEBOUNCE_TIMEOUT = 250;

const EMPTY_RESULTS = {
	result: [],
	duration: 0,
};

type ResultCallbacks = {
	onPreQueryStart?: () => void;
	onPreQuerySuccess?: () => void;
	onPreQueryFailure?: () => void;
	onPostQueryStart?: () => void;
	onPostQuerySuccess?: (duration: number, hasThirdPartyResults: boolean) => void;
	onPostQueryFailure?: () => void;
	onPostQueryCancel?: () => void;
};

type UseQuickFindResultsOptions = {
	postQueryDisabled?: boolean;
};

/**
 * Fetches search results using search parameters from the query
 * and filter stores. During and after requests, the results store is
 * updated accordingly. If multiple requests are fired only the last
 * request will cause the results store to be updated.
 */
export const useQuickFindResults = ({
	postQueryDisabled,
	onPreQueryStart,
	onPreQuerySuccess,
	onPreQueryFailure,
	onPostQueryStart,
	onPostQuerySuccess,
	onPostQueryFailure,
	onPostQueryCancel,
}: UseQuickFindResultsOptions & ResultCallbacks) => {
	const [bootstrap] = useBootstrap();
	const isSearchReady = useIsSearchReady();
	const [searchSessionId] = useSearchSessionId();
	const [selectedProduct] = useSelectedProduct();
	const { selectedFilters, entities } = useSelectedFilters({
		thirdPartyConfigs: bootstrap.thirdPartyConfigs,
		cloudId: bootstrap.cloudId,
	});
	const logException = useLogException();
	const [retryCount] = useRetryCount();
	const quickFindOpen = useQuickFindOpen();
	const quickFindQuery = useQuickFindQuery();
	const currentQueryFilter = useCurrentQueryFilter();
	const selectedQueryFilters = useSelectedQueryFilters();
	const { setJiraIssueFieldsLoading, setRequestedBackendExperiments } = useSearchActions();

	const prevQueryRef = useRef<string>();
	const prevQuickFindOpenRef = useRef<boolean>(quickFindOpen);
	const warmupRequestWasFired = useRef<boolean>(false);
	const prevSelectedQueryFiltersRef = useRef<SelectedQueryFilter[]>(selectedQueryFilters);
	const prevSelectedFilters = useRef<SelectedFiltersType>();
	const fetchRecentActivitiesPromiseRef = useRef<Promise<RecentActivities>>();
	const preQueryPromiseRef = useRef<CancellablePromise<RecentActivities>>();
	const postQueryPromiseRef = useRef<CancellablePromise<any>>();

	const { setUpdateRecentQueries, setFetchEntitiesRequestId, setFetchSpacesRequestId } =
		useQuickFindActions();

	const [preQueryLoading, setPreQueryLoading] = useState(true);
	const [preQueryError, setPreQueryError] = useState<Error | undefined>();

	const [postQueryLoading, setPostQueryLoading] = useState(false);
	const [postQueryError, setPostQueryError] = useState<Error | undefined>();

	const [recentQueries, setRecentQueries] = useState<RecentQueries>([]);
	const [filteredRecentQueries, setFilteredRecentQueries] = useState<RecentQueries>([]);
	const [filteredRecentActivities, setFilteredRecentActivities] =
		useState<RecentActivities>(EMPTY_RESULTS);
	const [searchResults, setSearchResults] = useState<SearchResults>(EMPTY_RESULTS);
	const [peopleAndTeams, setPeopleAndTeams] = useState<PeopleAndTeams>(EMPTY_RESULTS);

	/**
	 * Callback to update recent queries for the current product and user
	 */
	const fetchRecentQueries = useCallback(() => {
		if (!bootstrap.primaryProduct || !bootstrap.user?.id) {
			return;
		}

		const recentQueries = getRecentQueries(bootstrap.primaryProduct, bootstrap.user.id);
		setRecentQueries(recentQueries);
	}, [bootstrap.primaryProduct, bootstrap.user?.id, setRecentQueries]);

	/**
	 * Store the callback to update recent queries in the store. This is used to refresh recent queries
	 * when they are updated, e.g. when a search request is made or when a recent query is removed.
	 */
	useEffect(() => {
		fetchRecentQueries();
		setUpdateRecentQueries(fetchRecentQueries);
	}, [fetchRecentQueries, setUpdateRecentQueries]);

	/**
	 * Callback to fetch recent activities
	 */
	const fetchRecentActivitiesCallback = useCallback(async () => {
		if (bootstrap.cloudId) {
			return fetchRecentActivities(bootstrap.cloudId, bootstrap.aggAbsoluteUrl);
		} else {
			return [];
		}
	}, [bootstrap.cloudId, bootstrap.aggAbsoluteUrl]);

	/**
	 * Callback to fetch pre-query results and updates the store with the results
	 */
	const fetchQuickFindPreQuery = useCallback(
		(query: string, product?: string) => {
			if (!fetchRecentActivitiesPromiseRef.current) {
				setPreQueryLoading(true);
				onPreQueryStart?.();

				const fetchRecentActivitiesPromise = measureAsyncFunction(fetchRecentActivitiesCallback)
					.then((result) => {
						setPreQueryLoading(false);
						onPreQuerySuccess?.();

						return result;
					})
					.catch((error: Error) => {
						logException(error);
						setPreQueryError(error);
						onPreQueryFailure?.();

						return EMPTY_RESULTS;
					});

				fetchRecentActivitiesPromiseRef.current = fetchRecentActivitiesPromise;
			}

			const hideFilteredPreQueryResults =
				selectedQueryFilters.length > 0 ||
				(query.length && product !== undefined) ||
				(query.length && fg('quick_find_user_shard_query'));

			if (hideFilteredPreQueryResults) {
				setFilteredRecentQueries([]);
				setFilteredRecentActivities(EMPTY_RESULTS);
				return;
			}

			const preQueryPromise = new CancellablePromise(fetchRecentActivitiesPromiseRef.current);

			preQueryPromise
				.promise()
				.then((recentActivities) => {
					const filteredRecentActivites = filterSearchResults(recentActivities.result, query);
					const filteredRecentQueries = filterSearchResults(recentQueries, query);

					setFilteredRecentQueries(filteredRecentQueries);
					setFilteredRecentActivities({
						result: filteredRecentActivites,
						duration: recentActivities.duration,
					});
				})
				.catch((error: CancelError) => {
					// no-op on cancel
				});
		},
		[
			fetchRecentActivitiesCallback,
			logException,
			onPreQueryFailure,
			onPreQueryStart,
			onPreQuerySuccess,
			recentQueries,
			selectedQueryFilters.length,
		],
	);

	/**
	 * Callback to fetch confluence entities
	 */
	const fetchConfluenceEntitiesCallback = useCallback(async () => {
		let thirdPartyFilters: ThirdPartyFilters | undefined;
		if (fg('enable_quickfind_3p_search_results_confluence')) {
			thirdPartyFilters = getThirdPartyFiltersForQuickFind(bootstrap.thirdPartyConfigs);
		}

		return Promise.all([
			fetchConfluenceAnd3PEntities(
				bootstrap.aggAbsoluteUrl,
				quickFindQuery,
				bootstrap.primaryProduct,
				bootstrap.cloudId || '',
				searchSessionId,
				selectedFilters,
				thirdPartyFilters,
				bootstrap.experimentConfig,
				bootstrap.productEdition,
				bootstrap.productDataRegions,
				setRequestedBackendExperiments,
				entities,
			),
			fetchConfluenceSpaces(
				bootstrap.aggAbsoluteUrl,
				quickFindQuery,
				bootstrap.primaryProduct,
				bootstrap.cloudId || '',
				searchSessionId,
				selectedFilters,
				bootstrap.experimentConfig,
				bootstrap.productEdition,
				bootstrap.productDataRegions,
			),
		]).then((results) => {
			const [entities, spaces] = results;
			return {
				results: [...entities.fetchResults, ...spaces.fetchResults],
				fetchEntitiesRequestId: entities.fetchEntitiesRequestId ?? undefined,
				fetchSpacesRequestId: spaces.fetchSpacesRequestId ?? undefined,
			};
		});
	}, [
		bootstrap.aggAbsoluteUrl,
		bootstrap.primaryProduct,
		bootstrap.cloudId,
		bootstrap.experimentConfig,
		bootstrap.productEdition,
		bootstrap.productDataRegions,
		bootstrap.thirdPartyConfigs,
		quickFindQuery,
		searchSessionId,
		selectedFilters,
		setRequestedBackendExperiments,
		entities,
	]);

	/**
	 * Callback to fetch atlas entities
	 */
	const fetchAtlasEntitiesCallback = useCallback(
		async () =>
			fetchAtlasEntities(
				bootstrap.aggAbsoluteUrl,
				quickFindQuery,
				bootstrap.primaryProduct,
				bootstrap.cloudId || '',
				searchSessionId,
				selectedFilters,
				bootstrap.experimentConfig,
				bootstrap.productEdition,
				bootstrap.productDataRegions,
				setRequestedBackendExperiments,
			).then((results) => {
				const { fetchResults, fetchEntitiesRequestId } = results;
				return {
					results: fetchResults,
					fetchEntitiesRequestId: fetchEntitiesRequestId ?? undefined,
					fetchSpacesRequestId: undefined,
				};
			}),
		[
			bootstrap.aggAbsoluteUrl,
			bootstrap.cloudId,
			bootstrap.primaryProduct,
			bootstrap.experimentConfig,
			bootstrap.productEdition,
			bootstrap.productDataRegions,
			selectedFilters,
			quickFindQuery,
			searchSessionId,
			setRequestedBackendExperiments,
		],
	);

	/**
	 * Callback to fetch confluence entities
	 */
	const fetchMercuryEntitiesCallback = useCallback(
		async () =>
			fetchMercuryEntities(
				bootstrap.aggAbsoluteUrl,
				quickFindQuery,
				bootstrap.primaryProduct,
				bootstrap.cloudId || '',
				searchSessionId,
				selectedFilters,
				bootstrap.experimentConfig,
				bootstrap.productEdition,
				bootstrap.productDataRegions,
				setRequestedBackendExperiments,
			).then((results) => {
				const { fetchResults, fetchEntitiesRequestId } = results;
				return {
					results: [...fetchResults],
					fetchEntitiesRequestId: fetchEntitiesRequestId ?? undefined,
					fetchSpacesRequestId: undefined as string | undefined,
				};
			}),
		[
			bootstrap.aggAbsoluteUrl,
			bootstrap.cloudId,
			bootstrap.primaryProduct,
			bootstrap.experimentConfig,
			bootstrap.productEdition,
			bootstrap.productDataRegions,
			selectedFilters,
			quickFindQuery,
			searchSessionId,
			setRequestedBackendExperiments,
		],
	);

	/**
	 *  Callback to fetch jira entities
	 */
	const fetchJiraEntitiesCallback = useCallback(
		async () =>
			fetchJiraEntities(
				bootstrap.aggAbsoluteUrl,
				quickFindQuery,
				bootstrap.primaryProduct,
				bootstrap.cloudId || '',
				searchSessionId,
				selectedFilters,
				bootstrap.experimentConfig,
				bootstrap.productEdition,
				bootstrap.productDataRegions,
				setRequestedBackendExperiments,
			).then((results) => {
				const { fetchResults, fetchEntitiesRequestId } = results;
				return {
					results: [...fetchResults],
					fetchEntitiesRequestId: fetchEntitiesRequestId ?? undefined,
					fetchSpacesRequestId: undefined as string | undefined,
				};
			}),
		[
			bootstrap.aggAbsoluteUrl,
			bootstrap.primaryProduct,
			bootstrap.cloudId,
			bootstrap.experimentConfig,
			bootstrap.productEdition,
			bootstrap.productDataRegions,
			quickFindQuery,
			searchSessionId,
			selectedFilters,
			setRequestedBackendExperiments,
		],
	);

	/**
	 * Callback to fetch people and teams results
	 */
	const fetchPeopleAndTeamsCallback = useCallback(async () => {
		const { orgId, primaryProduct, user, cloudId } = bootstrap;
		if (orgId && primaryProduct && user?.id && cloudId) {
			// When no query filters are selected, get people and teams results
			// using the query
			if (selectedQueryFilters.length === 0) {
				return fetchPeopleAndTeamsResults(quickFindQuery, orgId, primaryProduct, user.id, cloudId);
			}

			// When a single query filter is selected and it is a contributor filter,
			// get people and teams results using the contributor name
			if (selectedQueryFilters.length === 1 && selectedQueryFilters[0].filterId === 'contributor') {
				return fetchPeopleAndTeamsResults(
					selectedQueryFilters[0].displayText,
					orgId,
					primaryProduct,
					user.id,
					cloudId,
				);
			}
		}

		return [];
	}, [bootstrap, quickFindQuery, selectedQueryFilters]);

	/**
	 * Function which fetches the post query results. This consists of a call to get
	 * product search results and a call to get people and teams. Given that Promise.allSettled
	 * is used, the slowest request will determine the time it takes to resolve.
	 */
	const fetchQuickFindPostQuery = useCallback(() => {
		const { cloudId = '', aggAbsoluteUrl } = bootstrap;

		// TODO: this is too nested - refactor this into switch or if-else statements
		const fetchProductEntities =
			bootstrap.primaryProduct === 'confluence' || selectedProduct === 'confluence'
				? fetchConfluenceEntitiesCallback
				: bootstrap.primaryProduct === 'townsquare'
					? fetchAtlasEntitiesCallback
					: bootstrap.primaryProduct === 'mercury'
						? fetchMercuryEntitiesCallback
						: bootstrap.primaryProduct === 'jira'
							? fetchJiraEntitiesCallback
							: () =>
									Promise.resolve({
										results: [],
										fetchEntitiesRequestId: undefined,
										fetchSpacesRequestId: undefined,
									});

		const fetchPeopleAndTeams =
			bootstrap.primaryProduct !== 'mercury' && !selectedProduct
				? fetchPeopleAndTeamsCallback
				: () => Promise.resolve([]);

		const postQueryPromise = new CancellablePromise(
			Promise.allSettled([
				measureAsyncFunction(fetchProductEntities),
				measureAsyncFunction(fetchPeopleAndTeams),
			]),
		);
		postQueryPromiseRef.current = postQueryPromise;

		onPostQueryStart?.();

		postQueryPromise
			.promise()
			.then((results) => {
				const productEntitiesStatus = results[0].status;
				const peopleAndTeamsStatus = results[1].status;

				// Post-query success is determined by product entities request, errors
				// from people and teams are ignored
				if (productEntitiesStatus === 'fulfilled') {
					const fetchedResults = results[0].value.result.results;
					setSearchResults({
						result: fetchedResults,
						duration: results[0].value.duration,
					});

					const hasThirdPartyResults = fetchedResults.some((result) =>
						result.id.startsWith('ari:cloud:graph::document'),
					);

					setFetchEntitiesRequestId(results[0].value.result.fetchEntitiesRequestId);
					setFetchSpacesRequestId(results[0].value.result.fetchSpacesRequestId);

					const peopleAndTeams =
						peopleAndTeamsStatus === 'fulfilled'
							? {
									result: results[1].value.result,
									duration: results[1].value.duration,
								}
							: EMPTY_RESULTS;
					setPeopleAndTeams(peopleAndTeams);

					const allDurations = results.map((result) =>
						result.status === 'fulfilled' ? result.value.duration : 0,
					);
					const maxDuration = Math.max(...allDurations);

					onPostQuerySuccess?.(maxDuration, hasThirdPartyResults);
				} else {
					setPostQueryError(new Error('Failed to fetch post query results'));
					onPostQueryFailure?.();
				}

				setPostQueryLoading(false);
				return results;
			})
			.then((results) => {
				const issueStatus = results[0].status;
				if (issueStatus === 'fulfilled' && fg('enable_status_jira_issues_quick_find')) {
					let fetchedResults = results[0].value.result.results;
					// Fetch extra fields for issues
					const issuesToFetch: string[] = [];

					if (fetchedResults && fetchedResults.length > 0) {
						fetchedResults.forEach((result) => {
							if (isSearchResultJiraIssue(result.data)) {
								const issueKey =
									result.data.issue?.webUrl?.split('/').at(-1) || result.data.url.split('/').at(-1);
								if (!!issueKey) {
									issuesToFetch.push(issueKey);
								}
							}
						});
					}

					if (issuesToFetch.length > 0) {
						setJiraIssueFieldsLoading(true);
						fetchJiraFields({ issueKeys: issuesToFetch, cloudId, aggAbsoluteUrl })
							.then((jiraHydrationResults) => {
								const issues = jiraHydrationResults.data?.jira.issuesByKey;
								if (issues && fetchedResults) {
									const issuesMap = new Map(issues.map((issue) => [issue.id, issue]));

									fetchedResults = fetchedResults.map((result) => {
										const issue = issuesMap.get(result.id);
										if (issue) {
											const colorName = issue.statusField.status.statusCategory?.colorName;
											if (result.data && result.data.status) {
												result.data.status.colorName = colorName;
											}
										}
										return result;
									});
								}

								const updatedResults =
									issueStatus === 'fulfilled'
										? {
												result: fetchedResults,
												duration: results[0].value.duration,
											}
										: EMPTY_RESULTS;
								setSearchResults(updatedResults);
							})
							.finally(() => {
								setJiraIssueFieldsLoading(false);
							});
					}
				}
			})
			.catch(() => {
				// Promise.allSettled will never reject, hence rejections only occur
				// when the promise is cancelled. In this case, we don't need to do anything.
			});
	}, [
		bootstrap,
		fetchAtlasEntitiesCallback,
		fetchConfluenceEntitiesCallback,
		fetchJiraEntitiesCallback,
		fetchMercuryEntitiesCallback,
		fetchPeopleAndTeamsCallback,
		onPostQueryFailure,
		onPostQueryStart,
		onPostQuerySuccess,
		selectedProduct,
		setFetchEntitiesRequestId,
		setFetchSpacesRequestId,
		setJiraIssueFieldsLoading,
	]);

	/**
	 * Debounced fetch function for quick find results. This callback should never mutate
	 * as the only dependency is the timeout value, hence it can be safely used in dependency array.
	 */
	const debouncedFetchQuickFindPostQuery = useDebouncedCallback(
		fetchQuickFindPostQuery,
		DEBOUNCE_TIMEOUT,
	);

	/**
	 * Warm-up request for user shard permissions cache
	 * when dialog is opened **for the first time**
	 */
	useEffect(() => {
		if (quickFindOpen && !warmupRequestWasFired.current && fg('quick_find_user_shard_query')) {
			debouncedFetchQuickFindPostQuery();
			warmupRequestWasFired.current = true;
		}
	}, [quickFindOpen, debouncedFetchQuickFindPostQuery]);

	/**
	 * Updates results when various Quick Find states change, this includes:
	 * - when search is ready
	 * - when the dialog opens
	 * - when the query changes
	 * - when recent queries or activities change
	 * - when selected entities or filters change
	 */
	useEffect(() => {
		if (!isSearchReady) {
			return;
		}

		if (preQueryPromiseRef.current) {
			preQueryPromiseRef.current.cancel();
		}
		fetchQuickFindPreQuery(quickFindQuery, selectedProduct);

		prevQuickFindOpenRef.current = quickFindOpen;

		if (!quickFindOpen || currentQueryFilter) {
			return;
		}

		if (
			quickFindQuery === prevQueryRef.current &&
			selectedFilters === prevSelectedFilters.current
		) {
			return;
		}

		prevQueryRef.current = quickFindQuery;
		prevSelectedQueryFiltersRef.current = selectedQueryFilters;
		prevSelectedFilters.current = selectedFilters;

		if (postQueryDisabled) {
			return;
		}

		const hasQuery = quickFindQuery || selectedQueryFilters.length > 0;

		if (!hasQuery) {
			onPostQueryCancel?.();
		}

		setSearchResults({ result: [], duration: 0 });
		setPeopleAndTeams({ result: [], duration: 0 });
		setPostQueryLoading(true);
		setPostQueryError(undefined);

		if (hasQuery) {
			// Cancel the inflight request if there is one
			if (postQueryPromiseRef.current) {
				postQueryPromiseRef.current.cancel();
			}

			setPostQueryLoading(true);
			debouncedFetchQuickFindPostQuery();
		}
	}, [
		fetchQuickFindPreQuery,
		debouncedFetchQuickFindPostQuery,
		quickFindQuery,
		retryCount,
		isSearchReady,
		quickFindOpen,
		selectedFilters,
		entities,
		onPostQueryCancel,
		postQueryDisabled,
		currentQueryFilter,
		selectedQueryFilters,
		selectedProduct,
	]);

	return useMemo(
		() =>
			getQuickFindContent({
				// Using refs here to ensure new results state is returned only
				// when the various result variables in the dependency array change
				query: prevQueryRef.current,
				selectedQueryFilters: prevSelectedQueryFiltersRef.current,
				quickFindOpen: prevQuickFindOpenRef.current,
				preQueryError,
				preQueryLoading,
				postQueryError,
				postQueryLoading,
				filteredRecentQueries,
				filteredRecentActivities,
				searchResults,
				peopleAndTeams,
				postQueryDisabled,
			}),
		[
			filteredRecentActivities,
			filteredRecentQueries,
			peopleAndTeams,
			postQueryDisabled,
			postQueryError,
			postQueryLoading,
			preQueryError,
			preQueryLoading,
			searchResults,
		],
	);
};

const useSearchResultsShownAnalytics = () => {
	const previousStateRef = useRef<State | undefined>();

	const content = useContent();
	const quickFindOpen = useQuickFindOpen();

	const { fireAnalyticsEvent } = useQuickFindAnalytics();
	const { commonAttributes } = useQuickFindAttributes();
	const [requestedBackendExperiments] = useRequestedBackendExperiments();

	useEffect(() => {
		// Store a copy of previous state before updating the ref
		const previousState = previousStateRef.current;
		previousStateRef.current = content ? content.state : undefined;

		// Don't fire analytics event if the dialog is closed
		if (!quickFindOpen || !content) {
			return;
		}

		// Don't fire analytics event if the state hasn't changed
		if (content.state === previousState) {
			return;
		}

		// Ignore states that do not have search results
		if (
			content.state === State.PREQUERY_LOADING ||
			content.state === State.PREQUERY_ERROR ||
			content.state === State.POSTQUERY_ERROR
		) {
			return;
		}

		/**
		 * Do not fire analytics event when the dialog is opened and post-query
		 * results are already returned. This is to have parity with the old
		 * quick find component.
		 *
		 * Whilst this may not be correct, this is here to ensure changes in metrics
		 * such as QSR and CTR are intentional.
		 *
		 * TODO: Discuss with DS/DE if this correct behaviour
		 */
		if (content.state === State.POSTQUERY_RESULTS && previousState === undefined) {
			return;
		}

		const resultsTypeMap = {
			PREQUERY_RESULTS: 'preQuerySearchResults' as const,
			PREQUERY_NO_RESULTS: 'preQuerySearchResults' as const,
			POSTQUERY_LOADING: 'cachedResults' as const,
			POSTQUERY_RESULTS: 'postQuerySearchResults' as const,
			POSTQUERY_NO_RESULTS: 'postQuerySearchResults' as const,
		};

		const resultsType = resultsTypeMap[content.state];

		const { sectionCount, resultCount } = getSectionsAndResultsCount();
		const resultTypeCounts = getResultTypeCounts();
		const productsInResults = getProductsInResults();

		const noResultsFetched =
			content.state === State.PREQUERY_NO_RESULTS ||
			content.state === State.POSTQUERY_NO_RESULTS ||
			resultCount === 0;

		// Don't fire cachedResults event if there are no results
		if (noResultsFetched && resultsType === 'cachedResults') {
			return;
		}

		const are3pResultsShown = productsInResults.some(is3pProductKey);

		fireAnalyticsEvent(
			onSearchResultsShown({
				attributes: {
					...commonAttributes,
					sectionCount,
					resultCount,
					resultTypeCounts,
					productsInResults,
					are3pResultsShown,
					noResultsFetched,
					...getCommonQueryAttributes(content.query),
					...requestedBackendExperiments,
				},
				nonPrivacySafeAttributes: {
					query: content.query,
				},
				resultsType,
			}),
		);
	}, [commonAttributes, content, fireAnalyticsEvent, quickFindOpen, requestedBackendExperiments]);
};

export const QuickFindResults = memo((options: UseQuickFindResultsOptions) => {
	const callbacks = useQuickFindResultsCallbacks();

	const useQuickFindResultsArgs = useMemo(
		() => ({ ...options, ...callbacks }),
		[options, callbacks],
	);

	const content = useQuickFindResults(useQuickFindResultsArgs);

	const { setContent } = useQuickFindActions();

	useEffect(() => {
		setContent(content);
	}, [content, setContent]);

	useSearchResultsShownAnalytics();

	return null;
});
